var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:" mb-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إضافة "+_vm._s(this.$route.meta.single))])],1)],1),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":_vm.items.length,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(item.phoneNumber)+" ")])]}},{key:"item.isMorning",fn:function(ref){
var item = ref.item;
return [(item.isMorning == true)?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v("صباحي")]):_vm._e(),(item.isMorning == false)?_c('v-chip',{attrs:{"color":"grey"}},[_vm._v("مسائي")]):_vm._e()]}},{key:"item.doctorArticles",fn:function(ref){
var item = ref.item;
return _vm._l((item.doctorArticles),function(a,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"color":"primary","dark":""}},[_c('a',{staticClass:"white--text",attrs:{"href":_vm.$url+'/'+a.fileUrl}},[_vm._v(_vm._s(a.title)+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteArticle(a.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fi fi-rr-cross-circle")])],1)],1)})}},{key:"item.fromTime",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertTo12HourFormat(item.fromTime))+" ")])]}},{key:"item.toTime",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertTo12HourFormat(item.toTime))+" ")])]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"100"}},[_c('v-img',{attrs:{"src":_vm.$url+'/'+item.imageUrl}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" fi fi-rr-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":item.isDoctorWeek == true},on:{"click":function($event){return _vm.markItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v(" "+_vm._s(item.isDoctorWeek == false ? 'fi fi-rr-star' : 'fi fi-rr-check-double')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("تحديد طبيب الاسبوع")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.uploadArticle(item)}}},on),[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" fi fi-rr-add-document ")])],1)]}}],null,true)},[_c('span',[_vm._v("رفع مقال")])])]}}])})],1),_c('Dialog'),_c('ArticleDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }